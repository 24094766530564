<template>
  <div>
    <div class="mt-5 pt-4 w-100">
      <h3 class="d-flex w-100 mt-2">
        <span class="pt-2 mx-auto">Eventos</span>
      </h3>
      <ApolloQuery :query="gqlNextEvents">
        <template slot-scope="{result:{loading, error, data}}">
          <!-- Loading -->
          <div
            v-if="loading"
            class="loading apollo text-center lead blue-grey-text py-5"
          >
            Cargando...
          </div>

          <!-- Error -->
          <div
            v-else-if="error"
            class="error apollo text-center lead blue-grey-text py-5"
          >
            Ocurrió un error
          </div>

          <!-- Result -->
          <div
            v-else-if="data && data.events && data.events.length"
            class="result apollo"
          >
            <div class="container">
              <div class="row justify-content-center pt-3 m-0 p-0">
                <div
                  v-for="event in data.events"
                  :key="event.id"
                  class="col-11 col-sm-9 col-md-5 col-lg-4 mb-4"
                >
                  <mdb-card
                    class="card-image"
                    style="background-image: url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg)"
                  >
                    <div
                      class="text-white text-center d-flex align-items-center
                      rgba-black-strong py-4 px-3"
                    >
                      <div class="w-100">
                        <div class="mh-20px mb-3">
                          <p class="float-left text-info mb-0">
                            <font-awesome-icon
                              icon="calendar-alt"
                              class="mr-1"
                            />
                            {{ event.date.year }} /
                            {{ event.date.month }} /
                            {{ event.date.day }}
                          </p>
                          <p class="float-right text-info mb-0">
                            <font-awesome-icon
                              icon="clock"
                              class="mr-1"
                            />
                            {{ event.date.hour }}:
                            {{ event.date.minute }}
                          </p>
                        </div>
                        <mdb-card-title
                          tag="header"
                          class="h3-responsive pt-2"
                        >
                          {{ event.name }}
                        </mdb-card-title>
                        <p
                          v-if="event.address"
                          class="text-light"
                        >
                          <font-awesome-icon
                            icon="map-marker-alt"
                            class="mr-1"
                          />
                          {{ event.address.line }}, {{ event.address.street }},
                          {{ event.address.city.name }},
                          {{ event.address.city.state.name }},
                          {{ event.address.city.state.country.name }}.
                        </p>
                        <router-link :to="`/eventos/${event.id}`">
                          <mdb-btn color="primary-base">
                            Ver más
                          </mdb-btn>
                        </router-link>
                      </div>
                    </div>
                  </mdb-card>
                </div>
              </div>
              <div class="row my-4">
                <div class="col">
                  <nav
                    aria-label="Events page navigation"
                  >
                    <ul class="pagination pg-blue justify-content-center">
                      <li class="page-item ">
                        <a
                          class="page-link"
                          tabindex="-1"
                        >Anterior</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link">1</a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link">2
                          <span class="sr-only">(actual)</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link">3</a>
                      </li>
                      <li class="page-item ">
                        <a class="page-link">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <!-- No result -->
          <div
            v-else
            class="no-result apollo container justify-content-around"
          >
            <mdb-alert
              color="warning"
              leave-animation="fadeOutDown"
            >
              <font-awesome-icon
                icon="exclamation-triangle"
                class="mr-2"
              />
              No hay próximos eventos. Regresa pronto para ver nuevos eventos.
            </mdb-alert>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>
<script>
import {mdbAlert, mdbBtn, mdbCard, mdbCardTitle} from 'mdbvue';
import gql from 'graphql-tag';
export default {
  name: 'Eventos',
  components: {
    mdbAlert,
    mdbBtn,
    mdbCard,
    mdbCardTitle,
  },
  data() {
    return {
      events: [],
      gqlNextEvents: gql`{
        events(orderBy:date_desc, first: 3){
          id
          name
          description
          date{
            year
            month
            day
            hour
            minute
          }
          address{
            line
            street
            zip
            city{
              name
              state{
                name
                country{
                  name
                }
              }
            }
          }
        }
      }`,
    };
  },
};
</script>
<style lang="scss">
  .pagination.pg-blue .page-item.active .page-link {
    background-color: $primary-color;
  }
</style>
